import JSConfetti from 'js-confetti';

document.addEventListener('launchConfetti', () => {
    const jsConfetti = new JSConfetti();

    // jsConfetti.addConfetti({
    //     emojis: ['🐿️', '🐿️', '🌲', `🌰`],
    //     emojiSize: 50,
    //     confettiNumber: 100,
    // });
    jsConfetti.addConfetti({
        confettiColors: [
            '#86AD01',
            '#CFDE99',
            '#FEFFF6',
            '#FBD9D9',
            '#AD0101',
            '#D6D6D6',
        ],
        confettiRadius: 6,
        confettiNumber: 500,
    });
});
