var userAgent = navigator.userAgent.toLowerCase();
if (/(chrome|firefox|safari)/.test(userAgent)) {
    var logo =
        'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJhIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NS43NSA0Ni43MiI+PHBhdGggZD0iTTQ0LjM3LDE1LjkzYzMuNzUsMTAuMjctLjI4LDIyLjA1LTEwLjA2LDI3LjcxLTUuNDcsMy4xNy0xMS43LDMuODEtMTcuMzYsMi4yOS01LjY2LTEuNTItMTAuNzMtNS4yLTEzLjg5LTEwLjY4QzEuMTEsMzEuODYsLjEyLDI4LjE3LDAsMjQuNTFjLS4xNS02LjM0LDMuNDMtMTIuOTQsOS4zLTE2LjMzLDUuODktMy40MSwxMi45MS0zLjA4LDE4LjMyLC4yNiwuODcsLjU0LC42OSwuOTgtLjI1LC42OS0zLjgxLTEuMTgtOC4wOC0uODMtMTEuOCwxLjMzLTcuMSw0LjExLTkuNTMsMTMuMi01LjQzLDIwLjMyLDQuMSw3LjExLDEzLjE4LDkuNTUsMjAuMjgsNS40NCw1LjUtMy4xOCw4LjE5LTkuMzUsNy4yMy0xNS4yNy0uMDItLjEyLS4wNS0uNTMsLjA5LS42MywxLjgzLTEuMjIsMy44MS0yLjc0LDUuOTktNC41NywuMTQtLjExLC41OCwuMDIsLjY0LC4xOVoiIHN0eWxlPSJmaWxsOiM1NTVjNjM7IGZpbGwtcnVsZTpldmVub2RkOyIvPjxwYXRoIGQ9Ik0zMC42Niw5LjI1Yy01LjcyLTUuMS0xNC4yNC02LjItMjEuMi0yLjEzLTIuNSwxLjQ2LTQuNTgsMy40OS02LjE2LDUuODMtLjk3LDEuNDQtMS4yLDEuMDktLjQ0LS4zOSwxLjk0LTMuODIsNC45Ni03LjEzLDguOS05LjQ0QzIxLjk0LTIuODQsMzQuNzUsLjAyLDQxLjYsOS4zNGMuMzcsLjQ1LC40MSwxLjE1LDAsMS42My0xLjExLDEuMzEtMy43OCw0LjEzLTguMzUsNy4yLTUuNCwzLjYyLTkuOTIsNS43OS0xMi43Miw2Ljk3LTEuOTMsLjgxLTEuOTgsLjMxLS40OC0uOTcsMi4zMy0xLjk5LDUuNzUtNS4wNyw3Ljk4LTcuODIsMS43OC0yLjIsMi44Ni00LjA2LDMuMDktNS42MiwuMDYtLjQzLS4xMi0xLjExLS40Ni0xLjQ4WiIgc3R5bGU9ImZpbGw6I2U1MDAzNjsgZmlsbC1ydWxlOmV2ZW5vZGQ7Ii8+PC9zdmc+';
    var boxStyle = [
        'font-family: sans-serif',
        'font-size: 24px',
        'border-radius:4px',
        'color:#fff',
        'background:#111',
    ].join(';');
    if (userAgent.indexOf('chrome') > -1) {
        boxStyle += ';';
        boxStyle += [
            'background: #111 url(' + logo + ') 15px 12px no-repeat',
            'background-size: 24px 24px',
            'padding:10px 22px 10px 50px',
            'background-color:#111; font-weight:800; color:white;font-size:24px;',
            'border-radius:12px',
        ].join(';');
    }
    var endStyle = ['background-color: transparent'].join(';');
    console.log('\n %c EVO Studios %c \n\n', boxStyle, endStyle);

    var linkStyle = ['font-family: sans-serif', 'font-size: 14px'].join(';');
    console.log('%c https://www.evostudios.it', linkStyle);
} else {
    window.console && console.log('EVO Studios');
}
